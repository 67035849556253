import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import {
  Layout,
  ModalCardVerification,
  NavigationButtons,
  TextInput,
} from "components";
import { endpoints, errorMessages } from "config";
import { usePaymentStatus, useRequest } from "hooks";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState, activeOrderActions, shoppingCartActions } from "state";
import * as yup from "yup";
import { images } from "./constants";
import { sanitizeName } from "utils/sanitizeName";

const PAYMENT_ERROR_MESSAGES: { [a: string]: string } = {
  cc_rejected_bad_filled_card_number: "verifique o numero do cartão",
  cc_rejected_bad_filled_date: "verifique a data de validade",
  cc_rejected_bad_filled_other: "verifique as informações preenchidas",
  cc_rejected_bad_filled_security_code: "verifique a codigo de segurança",
  cc_rejected_blacklist: "o pagamento não pode ser processado",
  cc_rejected_call_for_authorize: "confirme a operação no app do seu banco",
  cc_rejected_card_disabled: "metodo de pagamento não autorizado",
  cc_rejected_card_error: "o pagamento não pode ser processado",
  cc_rejected_duplicated_payment:
    "pagamento com valor duplicado, tente outro metodo de pagamento",
  cc_rejected_high_risk: "pagamento recusado, tente outro metodo de pagamento",
  cc_rejected_insufficient_amount: "pagamento recusado, saldo insuficiente",
  cc_rejected_max_attempts:
    "muitas tentativas com este cartão, tente outro metodo de pagamento",
  cc_rejected_other_reason: "não foi possivel processar pagamento",
};

const schema = yup
  .object({
    cardNumber: yup
      .string()
      .required(errorMessages.cardNumber)
      .min(16, errorMessages.cardLength),
    expirationDate: yup.string().required(errorMessages.expirationDate), // formato ##/##
    cvv: yup.string().required(errorMessages.cvv),
    document: yup.string().required(errorMessages.document),
    name: yup.string().required(errorMessages.name),
    address: yup.string().required(errorMessages.addressRequired),
    zipcode: yup.string().required(errorMessages.zipCodeRequired),
    address_number: yup.string().required(errorMessages.addressNumberRequired),
    address_complement: yup
      .string()
      .required(errorMessages.addressComplementRequired),
    address_city: yup.string().required(errorMessages.addressCityRequired),
    address_uf: yup.string().required(errorMessages.addressUfRequired),
  })
  .required();

const AddCreditCard = () => {
  const [savedCard, setSavedCard] = useState<boolean>(false);
  const [cardId, setCardId] = useState<
    { id: string; card_token: string } | undefined
  >(undefined);
  const [isVerificationModalActive, setIsVerificationModalActive] =
    useState(false);
  const user = useSelector((state: RootState) => state.user);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      cardNumber: "",
      expirationDate: "",
      cvv: "",
      document: "",
      name: "",
      address: "",
      zipcode: "",
      address_number: "",
      address_complement: "",
      address_city: "",
      address_uf: "",
    },
  });

  const navigate = useNavigate();

  const next = () => {
    try {
      handleSubmit(onSubmit)();
    } catch (err) {
      console.log("failed onSubmit...");
      setIsVerificationModalActive(false);
      setLoading(false);
    }
  };

  const [rejectedError, setRejectedError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const { status: paymentStatus } = usePaymentStatus();

  const { state } = useLocation();

  useEffect(() => {
    // @ts-ignore
    const card = state?.card;

    if (card) {
      // set card form data...
      setSavedCard(true);
      setCardId(card);

      // console.log("customer id:", card?.customer_id);

      setValue(
        "cardNumber",
        // TODO: change the zeroes to something like "#" or "*"
        `0000 0000 0000 ${card.last_digits}`
      );
      setValue("expirationDate", `**/**`);

      setValue("name", card.name);

      setValue("document", "***.***.***-**");

      setValue("cvv", "***");
      setValue("address", "--");
      setValue("zipcode", "--");
    } else {
      setSavedCard(false);
    }

    // eslint-disable-next-line
  }, [state]);

  const { post } = useRequest();

  const dispatch = useDispatch();

  const activeOrder = useSelector(
    (state: RootState) => state.activeOrder.order
  );

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setLoading(true);

    data.name = data.name.toUpperCase();

    console.log("card data:", data);

    console.log("ACTIVE ORDER:", activeOrder);

    if (activeOrder === undefined) {
      navigate("/order-confirmation");
      return;
    }

    const session = await post(endpoints.paymentSession, null);

    if (session.error) {
      if (session.statusCode === 400) {
        setRejectedError(session.message);
      } else {
        setRejectedError(
          "Algo deu errado! Contate o suporte para mais informações. [cod. 702]"
        );
      }
      setOpenSnackbar(true);
      setLoading(false);
      setIsVerificationModalActive(false);
      return;
    }

    const payment_data: Record<string, string | number | undefined> = {
      payment_method: "CREDIT_CARD",
      order_id: activeOrder?.id,
      card_id: cardId?.id || undefined,
      card_number: data.cardNumber,
      expiration_date:
        data.expirationDate.slice(0, 2) + "/20" + data.expirationDate.slice(-2),
      holder: data.name,
      securityCode: data.cvv,
      holder_document: cardId ? undefined : data.document,
      address_line_1: data.address,
      address_zip_code: data.zipcode,
    };

    if (!cardId?.card_token) {
      const PagSeguro = window.PagSeguro;

      if (!PagSeguro) {
        setRejectedError(
          "Algo deu errado! Contate o suporte para mais informações. [cod. 701]"
        );
        setOpenSnackbar(true);
        setLoading(false);
        setIsVerificationModalActive(false);
        return;
      }

      PagSeguro.setUp({
        session: session.session,
        env: session.env,
      });

      const request = {
        data: {
          customer: {
            name: sanitizeName(user.name || "sem nome"),
            email: user.email,
            phones: [
              {
                country: "55",
                area: "11",
                number: "999999999",
                type: "MOBILE",
              },
              {
                country: "55",
                area: "11",
                number: "999999999",
                type: "HOME",
              },
              {
                country: "55",
                area: "11",
                number: "999999999",
                type: "BUSINESS",
              },
            ],
          },
          paymentMethod: {
            type: "CREDIT_CARD",
            installments: 1,
            card: cardId?.card_token
              ? {
                  id: cardId?.card_token,
                }
              : {
                  number: data.cardNumber,
                  expMonth: data.expirationDate.slice(0, 2),
                  expYear: "20" + data.expirationDate.slice(-2),
                  holder: {
                    name: sanitizeName(data.name),
                  },
                },
          },
          amount: {
            value: Number(`${activeOrder.fullprice}00`.replace(/\D/g, "")),
            currency: "BRL",
          },
          billingAddress: {
            street: data.address,
            number: data.address_number,
            complement: data.address_complement || "casa",
            regionCode: data.address_uf,
            country: "BRA",
            city: data.address_city,
            postalCode: data.zipcode.replace(/\D/g, ""),
          },
          shippingAddress: {
            street: activeOrder.delivery_street,
            number: activeOrder.delivery_address_number,
            complement: activeOrder.delivery_address_complement || "casa",
            regionCode: activeOrder.delivery_state,
            country: "BRA",
            city: activeOrder.delivery_city,
            postalCode: activeOrder.delivery_address_zipcode.replace(/\D/g, ""),
          },
          dataOnly: false,
        },
      };

      console.log("request", request);

      const pagStatus = await new Promise<{
        success: boolean;
        token?: string;
        message?: string;
        err?: unknown;
      }>((resolve) => {
        PagSeguro.authenticate3DS(request)
          .then((result) => {
            console.log("result", result);
            if (result.status === "AUTH_NOT_SUPPORTED")
              return resolve({
                success: false,
                message: "cartão não suporta autenticação 3DS, tente outro.",
              });

            if (result.status === "AUTH_FLOW_COMPLETED")
              return resolve({
                token: result.id,
                success: true,
              });

            if (result.status === "CHANGE_PAYMENT_METHOD")
              return resolve({
                success: false,
                message:
                  "método de pagamento negado, por favor tente outro cartão.",
              });

            if (result.status === "REQUIRE_CHALLENGE") {
              return resolve({
                token: undefined,
                success: false,
                message:
                  "necessário realizar a confirmação de segurança com o banco.",
              });
            }
          })
          .catch((err) => {
            resolve({
              token: undefined,
              success: false,
              err,
              message:
                "erro ao processar pagamento, tente novamente. " + String(err),
            });
          });
      });

      if (!pagStatus.success) {
        console.log("failed request...", pagStatus.err);
        setRejectedError(
          pagStatus.message || "erro ao processar pagamento, tente novamente."
        );
        setOpenSnackbar(true);
        setLoading(false);
        setIsVerificationModalActive(false);
        return;
      }

      if (pagStatus.token) {
        payment_data["token"] = pagStatus.token;
      }
    }

    console.log(payment_data);

    setIsVerificationModalActive(true);
    const response = await post(endpoints.chargePayment, payment_data);

    console.log("response", response);

    if (response.error) {
      console.log("failed request...");

      if (response.statusCode === 400) setRejectedError(response.message);
      else if (response.statusCode === 429) {
        setRejectedError(
          "Muitas tentativas de pagamento, tente novamente mais tarde."
        );
      } else {
        setRejectedError(
          "Algo deu errado! Contate o suporte para mais informações. [cod. 703]"
        );
      }
      setOpenSnackbar(true);

      setLoading(false);
      setIsVerificationModalActive(false);
    } else {
      console.log("successfully requested payment with credit card", response);

      dispatch(
        shoppingCartActions.setPixData({
          id: response.id,
          status: response.status,
        })
      );

      const map = {
        pending: () => {
          // TODO: ?
          console.log("pagamento pendente...");
          setIsVerificationModalActive(true);
        },
        finished: () => {
          navigate("/order-confirmation-status");
          dispatch(activeOrderActions.setIsOrderActive(false));
          dispatch(shoppingCartActions.cleanShoppingCart());
          setIsVerificationModalActive(false);
        },
        failed: () => {
          console.log("PAYLOAD DE RESPONSE", response);
          const charge_status = response.charges[0].last_transaction.status;
          const status_message =
            response.charges[0].last_transaction.acquirer_message?.toLowerCase();

          setRejectedError(
            `erro ao processar pagamento: ${
              PAYMENT_ERROR_MESSAGES[charge_status] || status_message
            }`
          );
          setOpenSnackbar(true);
          setIsVerificationModalActive(false);
        },
      };

      const status = response.status.toLowerCase();

      let callback = map[status as keyof typeof map];

      setLoading(false);
      if (callback) callback();
    }
  };

  const onChangeZipCode = (e: any) => {
    const numbers = e.target.value.replace(/\D/g, "");

    if (numbers.length !== 8) return;

    const uri = `https://viacep.com.br/ws/${numbers}/json/`;

    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      fetch(uri)
        .then((response) => response.json())
        .then((data) => {
          if (data.erro) {
            setRejectedError("CEP não encontrado");
            setOpenSnackbar(true);
            return;
          }

          setValue("address", data.logradouro);
          setValue("address_complement", data.complemento);
          setValue("address_city", data.localidade);
          setValue("address_uf", data.uf);
        })
        .catch((error) => {
          console.error("Error fetching CEP:", error);
        })
        .finally(() => {
          timeoutRef.current = null;
        });
    }, 1000);
  };

  useEffect(() => {
    if (
      isVerificationModalActive &&
      paymentStatus?.toLowerCase() === "failed"
    ) {
      setIsVerificationModalActive(false);
    }
    // eslint-disable-next-line
  }, [paymentStatus]);

  // useRedirectOnOrderStatusChange();

  return (
    <>
      <ModalCardVerification isActive={isVerificationModalActive} />
      <Layout>
        <Box
          sx={{
            width: "100%",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            pb: "45px",
          }}
        >
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            sx={{ width: "calc(100% - 16px)", mt: "120px" }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={() => setOpenSnackbar(false)} severity="error">
              {rejectedError}
            </Alert>
          </Snackbar>
          {/* <ErrorMessage text={rejectedError} /> */}
          <Box
            sx={{
              mb: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                height: "70px",
                objectFit: "cover",
              }}
              src={images.bandeiras}
              alt="bandeiras"
            />
          </Box>

          <form>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box>número do cartão</Box>
              <TextInput
                control={control}
                error={errors["cardNumber"]}
                name="cardNumber"
                required
                placeholder="#### #### #### ####"
                mask={{
                  format: "#### #### #### ####",
                }}
                autoFocus
                disabled={savedCard}
              />
            </Box>
            {/* TODO descomentar quando tiver endpoint de pagamento */}
            {/* <ErrorMessage text={error} /> */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "46%",
                }}
              >
                <Box>validade</Box>
                <TextInput
                  control={control}
                  error={errors["expirationDate"]}
                  name="expirationDate"
                  required
                  placeholder="##/##"
                  mask={{
                    format: "##/####",
                  }}
                  onValueChange={(v: any) => {
                    const value = v.formattedValue.trim();

                    if (value.length > 4) {
                      const month = value.slice(0, 2);
                      const year = value.slice(-2);
                      setValue("expirationDate", `${month}/${year}`);
                      return;
                    }

                    setValue("expirationDate", value);
                  }}
                  disabled={savedCard}
                />
              </Box>
              {/* TODO descomentar quando tiver endpoint de pagamento */}
              {/* <ErrorMessage text={error} /> */}
              {!savedCard && (
                <Box
                  sx={{
                    width: "46%",
                  }}
                >
                  <Box>CVV</Box>
                  <TextInput
                    control={control}
                    error={errors["cvv"]}
                    name="cvv"
                    required
                    placeholder="###"
                    mask={{
                      format: "###",
                    }}
                  />
                </Box>
              )}
              {/* TODO descomentar quando tiver endpoint de pagamento */}
              {/* <ErrorMessage text={error} /> */}
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box>nome do titular</Box>
              {/* // TODO: make uppercase */}
              <TextInput
                control={control}
                error={errors["name"]}
                name="name"
                required
                disabled={savedCard}
              />
            </Box>
            {/* TODO descomentar quando tiver endpoint de pagamento */}
            {/* <ErrorMessage text={error} /> */}
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box>CPF do titular</Box>
              <TextInput
                control={control}
                error={errors["document"]}
                name="document"
                placeholder="###.###.###-##"
                mask={{
                  format: "###.###.###-##",
                }}
                required
                disabled={savedCard}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box>CEP de cobrança</Box>
              <TextInput
                control={control}
                error={errors["zipcode"]}
                name="zipcode"
                placeholder="##.###-###"
                mask={{
                  format: "##.###-###",
                }}
                required
                onChange={onChangeZipCode}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box>endereço</Box>
              <TextInput
                control={control}
                error={errors["address"]}
                name="address"
                required
              />
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box>número</Box>
              <TextInput
                control={control}
                error={errors["address_number"]}
                name="address_number"
                required
              />
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box>complemento</Box>
              <TextInput
                control={control}
                error={errors["address_complement"]}
                name="address_complement"
                required
              />
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box>cidade</Box>
              <TextInput
                control={control}
                error={errors["address_city"]}
                name="address_city"
                required
              />
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box>uf</Box>
              <TextInput
                control={control}
                error={errors["address_uf"]}
                name="address_uf"
                required
              />
            </Box>

            {/* TODO descomentar quando tiver endpoint de pagamento */}
            {/* <ErrorMessage text={error} /> */}
            {/* <Box
              sx={{
                width: "100%",
              }}
            >
              <Box>apelido do cartão (opcional)</Box>
              <TextInput
                control={control}
                error={errors["nickname"]}
                name="nickname"
              />
            </Box> */}
            {/* TODO descomentar quando tiver endpoint de pagamento */}
            {/* <ErrorMessage text={error} /> */}
          </form>
        </Box>
      </Layout>
      <NavigationButtons
        leftTitle="voltar"
        leftAction={() => navigate("/payment-type")}
        rightTitle="avançar"
        rightAction={next}
        rightLoading={loading}
        leftDisabled={loading}
      />
    </>
  );
};

export default AddCreditCard;
