export const endpoints = {
  // - v2
  V2: {
    LIST_ORDERS: `/v2/orders`,
  },

  // - v1

  // auth
  login: `/auth/initiate-phone-login`,
  accepted_terms_of_use: (id: string) => `/users/${id}`,
  signup: `/auth/phone-signup`,
  codeVerify: `/auth/verify-sms-code`,
  invite: `/auth/invite-signup`,
  profile: "/auth/profile",
  // orders
  orders: `/orders`,
  createOrder: `/orders`,
  calculatePricing: `/orders/calculate-customer`,
  status: (orderId: string | number) => `/orders/${orderId}/status`,
  // products
  products: `/v2/products`,
  // chat
  messages: (orderId: string | number) => `/orders/${orderId}/messages`,
  // address
  lastAddresses: `/orders/last-addresses`,
  chatWebsocket: `/chat`,
  orderStatusWebsocket: `/order-status`,
  productsWebsocket: `/product`,
  // payment
  chargePayment: `/payment/charge`,
  paymentStatusWebsocket: `/payment-status`,
  paymentSession: `/payment/divipay-session`,
  // cards
  cards: `/payment/credit-cards`,
  cardDelete: `/payment/credit-cards`,
  // brands
  brandsAvailables: `/products/brands`,
  // store
  store: `/store-configuration`,
  // user
  users: `/users`,
  //banner
  banners: "/banners",
};
