const validations = {
  required: `é um campo obrigatório`,
  atLeast: (num: number) => `deve ter pelo menos ${num} caracteres`,
  atMax: (num: number) => `deve ter no máximo ${num} caracteres`,
  exactly: (num: number) => `deve ter exatamente ${num} caracteres`,
};

export const errorMessages = {
  emailRequired: `email ${validations.required}`,
  emailFormat: `email inválido`,

  passwordRequired: `Senha ${validations.required}`,
  passwordMinLength: `Senha ${validations.atLeast(8)}`,

  nameRequired: `Nome ${validations.required}`,
  nameMinLength: `Nome ${validations.atLeast(4)}`,

  companyNameRequired: `Razão social ${validations.required}`,
  companyNameMinLength: `Razão social ${validations.atLeast(4)}`,

  cnpjRequired: `CNPJ ${validations.required}`,
  cnpjLength: `CNPJ ${validations.exactly(14)}`,

  stateRegistrationRequired: `Inscrição estadual ${validations.required}`,
  stateRegistrationMinLength: `Inscrição estadual ${validations.atLeast(8)}`,

  cpfRequired: `CPF ${validations.required}`,
  cpfLength: `CPF ${validations.exactly(11)}`,
  cpfInvalid: `CPF inválido`,

  phoneRequired: `Telefone ${validations.required}`,
  phoneMinLength: `Telefone ${validations.atLeast(11)}`,
  phoneMaxLength: `Telefone ${validations.atMax(13)}`,

  codeRequired: `Código ${validations.required}`,
  codeMinLength: `Código ${validations.exactly(4)}`,

  streetRequired: `Endereço ${validations.required}`,

  numberRequired: `Número ${validations.required}`,
  numberMinLength: `Número ${validations.atLeast(1)}`,

  complementRequired: `Complemento ${validations.required}`,

  cardNumber: `número do cartão ${validations.required}`,
  cardLength: `número do cartão ${validations.exactly(16)}`,

  expirationDate: `validade ${validations.required}`,

  cvv: `cvv ${validations.required}`,

  document: `CPF/CNPJ ${validations.required}`,

  name: `nome do titular ${validations.required}`,

  termsOfUseRequired: `você precisa aceitar os termos de uso para realizar o cadastro`,

  addressRequired: `endereço ${validations.required}`,
  addressNumberRequired: `rua ${validations.required}`,
  addressComplementRequired: `complemento ${validations.required}`,
  addressCityRequired: `cidade ${validations.required}`,
  addressUfRequired: `uf ${validations.required}`,
  zipCodeRequired: `CEP ${validations.required}`,
};
